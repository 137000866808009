import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Col, Container, DangerouslySetInnerHtml, Layout, Row } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';

import { LdsPageTypes } from './models';

import './LdsPage.scss';

const LdsPage: FC<LdsPageTypes.LdsPageType> = ({ data: { ldsPage } }) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } = ldsPage.seo;
  const { title, level, body, url, lang } = ldsPage;

  return (
    <Layout className="lds-page-layout">
      <Seo
        {...{
          seo: ldsPage.seo,
          openGraph: ldsPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Breadcrumbs level={level} />
      <section className="lds-page">
        <Container fluid>
          <Row className="lds-page__row">
            <Col xl="8" className="col--no-gutters lds-page__row__col">
              <h1 className="lds-page__title h2">{title}</h1>
              {body ? <DangerouslySetInnerHtml html={body} className="lds-page__content" /> : null}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    ldsPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      body
    }
  }
`;

export default LdsPage;
